import { Image, useMediaQuery } from "@chakra-ui/react";

export default function SectionD() {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  return isMobile ? (
    <Image src={"/images/dralvaroamaral-2.png"} w={"100vw"} />
  ) : (
    <Image src="/images/dralvaroamaral-2-desktop.png" w={"100vw"} />
  );
}
