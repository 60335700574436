import { Center, Flex, Text } from "@chakra-ui/react";

export default function SectionB() {
  return (
    <Flex
      w="full"
      position={"relative"}
      bottom="8px"
      backgroundImage={"linear-gradient(#262d2c 20%, #d8d8d8)"}
    >
      <Center
        w="full"
        h="100%"
        p={{ base: "30px 30px 48px 30px", xl: "80px 120px 100px 120px" }}
        borderRadius={"30px 30px 0px 30px"}
        bg="#781717"
        flexDirection={"column"}
        color="gray.50"
        // fontSize={{ base: "sm", md: "2xl", xl: "3xl", "2xl": "4xl" }}
        lang="pt-BR"
      >
        <Text
          className="hyphen"
          textIndent={{
            base: "1.5rem",
            md: "2.5rem",
            xl: "3rem",
            "2xl": "3.5rem",
          }}
          mb={{ base: "20px", md: "40px" }}
        >
          Sou cirurgião vascular e endovascular e atuo no diagnóstico e
          tratamento de doenças relacionadas aos sistemas arterial, venoso e
          linfático. Ou seja, doenças vasculares que acometem os membros
          superiores e inferiores, tórax, abdome, pelve e região cervical.
        </Text>
        <Text
          className="hyphen"
          textIndent={{
            base: "1.5rem",
            md: "2.5rem",
            xl: "3rem",
            "2xl": "3.5rem",
          }}
        >
          Como cirurgião endovascular, posso realizar o tratamento das doenças
          vasculares navegando por dentro dos vasos, de forma minimamente
          invasiva, através de finos tubos denominados de cateteres. Isso
          possibilita que o tratamento seja realizado sem cortes e com
          recuperação mais rápida.
        </Text>
      </Center>
    </Flex>
  );
}
