//@ts-nocheck
import "./styles.css";
import { Center, SimpleGrid } from "@chakra-ui/react";
import { useEffect } from "react";
import Instafeed from "instafeed.js";
export default function InstafeedSection() {
  useEffect(() => {
    var feed = new Instafeed({
      get: "user",
      accessToken:
        "IGQWROcmJENFMxYW5taGgzMVhScFctWC1CcENXR3M1dDUtN0taS3A1T1ZApeTB2aHJ2VTdBRlg4YjdiLUZAJYm1YYWNUbUFPNTlkOWpDTnpYTk1IMWdZAWTVCQmw3T0xFampmMUlwUEd5ZAHF1VjJ4b0l4Sy1oSGoyUGsZD",
      limit: 9,
    });
    feed.run();
  }, []);
  return (
    <Center>
      <SimpleGrid
        columns={3}
        maxW={"900px"}
        w={"full"}
        justifyContent={"center"}
        spacing={2}
        p={2}
        pt={"20px"}
        id="instafeed"
        className="instafeed-container"
      ></SimpleGrid>
    </Center>
  );
}
