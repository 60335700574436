import { Flex, Center, Text } from "@chakra-ui/react";

export default function SectionC() {
  return (
    <Flex w="full" position={"relative"} bottom="9px">
      <Center
        w="full"
        h="100%"
        p={{ base: "0px 30px 30px 30px", xl: "0px 120px 80px 120px" }}
        bg="#d8d8d8"
        flexDirection={"column"}
        color="gray.900"
        // fontSize={{ base: "sm", md: "2xl", xl: "3xl", "2xl": "4xl" }}
        lang="pt-BR"
      >
        <Center
          alignSelf={"center"}
          p="12px"
          bg="#c9c5c2"
          borderRadius={"22px 22px 0px 22px"}
          position="relative"
          bottom={{ base: "20px", xl: "36px" }}
          fontFamily={"Sitka"}
          fontWeight={"medium"}
          fontSize={{ base: "18px", md: "24px", xl: "30px", "2xl": "40px" }}
          w={{ base: "80%", xl: "800px", "2xl": "1000px" }}
        >
          Quando consultar um cirurgião vascular?
        </Center>
        <Text
          className="hyphen"
          textIndent={{
            base: "1.5rem",
            md: "2.5rem",
            xl: "3rem",
            "2xl": "3.5rem",
          }}
          mb={{ base: "20px", md: "40px" }}
        >
          No momento em que identificar quaisquer sinais ou sintomas de doenças
          vasculares, pois elas podem causar grandes complicações para a sua
          saúde.
        </Text>
        <Text
          className="hyphen"
          textIndent={{
            base: "1.5rem",
            md: "2.5rem",
            xl: "3rem",
            "2xl": "3.5rem",
          }}
          fontWeight={"medium"}
        >
          Prevenir e tratar precocemente fazem toda a diferença!
        </Text>
      </Center>
    </Flex>
  );
}
