import { VStack, Tooltip, Button, Link, Image } from "@chakra-ui/react";
import "./App.css";
import Footer from "./components/Footer";
import SectionA from "./components/SectionA";
import SectionB from "./components/SectionB";
import SectionC from "./components/SectionC";
import SectionD from "./components/SectionD";
import SectionE from "./components/SectionE";
import SectionF from "./components/SectionF";
import SectionG from "./components/SectionG";
import { useState, useEffect, useRef } from "react";
import InstafeedSection from "./components/InstafeedSection";

function App() {
  const faqRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    // let params = document.location.search;
    let params = new URLSearchParams(document.location.search);
    const shouldScrollToFaq = params.get("faq");
    if (shouldScrollToFaq) {
      setTimeout(() => {
        if (faqRef.current) {
          faqRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="App" style={{ fontFamily: "Poppins" }}>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <div ref={faqRef}>
        <SectionE />
      </div>
      <InstafeedSection />
      <SectionF />
      <SectionG />
      <Footer />
      <VStack position="fixed" bottom={"52px"} right={"32px"} zIndex={9999}>
        {isScrolled && (
          <Tooltip
            color="white"
            label="Voltar ao topo"
            placement="left"
            px="12px"
            borderRadius={"4px"}
            hasArrow
          >
            <Button
              width="60px"
              h="60px"
              borderRadius={"full"}
              mb="12px"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" />
              </svg>
            </Button>
          </Tooltip>
        )}
        <Button
          id="eclore-wpp-button"
          as={Link}
          isExternal
          href="https://api.whatsapp.com/send?phone=5598992231294&text=Olá!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20o%20Dr.%20Alvaro%20Amaral"
          _hover={{
            transform: "scale(1.1, 1.1)",
          }}
          size={"sm"}
          w={"60px"}
          p={0}
          borderRadius={"full"}
        >
          <Tooltip
            color="white"
            bg="green.400"
            label="Agendar consulta"
            placement="left"
            px="12px"
            borderRadius={"4px"}
            hasArrow
          >
            <Image
              id="whatsapp-image-id"
              src="/images/wpp.png"
              width={"60px"}
              height={"60px"}
            />
          </Tooltip>
        </Button>
      </VStack>
    </div>
  );
}

export default App;
