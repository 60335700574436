import { Center, Link } from "@chakra-ui/react";
import React from "react";

export default function Footer() {
  return (
    <Center
      as={Link}
      isExternal
      href="https://www.instagram.com/_agenciaopen/"
      height={{ base: "80px", xl: "100px" }}
      w="full"
      fontSize={{ base: "14px", xl: "22px", "2xl": "28px" }}
    >
      Site desenvolvido por Agência Open
    </Center>
  );
}
