import { Button, Flex, Image, Link } from "@chakra-ui/react";

export default function SectionF() {
  return (
    <Flex
      w="full"
      h={{ base: "300px", sm: "400px", xl: "650px", "2xl": "760px" }}
      alignItems={"flex-end"}
    >
      <Image
        src="/images/dralvaroamaral-3.png"
        position={"absolute"}
        w={{ base: "220px", sm: "320px", xl: "500px", "2xl": "600px" }}
        left={{ base: 0, xl: "100px", "2xl": "250px" }}
      />
      <Flex
        w="full"
        h={{ base: "260px", sm: "340px", md: "400px", "2xl": "450px" }}
        bg="#781717"
        borderRadius="30px 30px 0px 0px"
        direction={"column"}
        alignItems={"flex-end"}
        py={{ base: "60px", xl: "70px", "2xl": "70px" }}
        px={{ base: "20px", xl: "100px", "2xl": "150px" }}
      >
        <Button
          as={Link}
          isExternal
          href={
            "https://www.instagram.com/dralvaroamaral/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
          }
          borderRadius={{
            base: "6px 6px 0px 6px",
            xl: "16px 16px 0px 16px",
            "2xl": "20px 20px 0px 20px",
          }}
          // w={{ base: "260px", xl: "500px" }}
          h={{ base: "28px", xl: "60px", "2xl": "80px" }}
          p={{ base: "10px", xl: "30px" }}
          fontSize={{ base: "12px", xl: "30px", "2xl": "40px" }}
          fontWeight={"thin"}
          color="gray.600"
          mb="12px"
        >
          Me acompanhe no&nbsp;<strong>Instagram</strong>
        </Button>
        <Button
          as={Link}
          isExternal
          href={
            "https://api.whatsapp.com/send?phone=5598992231294&text=Olá!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20o%20Dr.%20Alvaro%20Amaral"
          }
          bg="#50b579"
          _hover={{
            bg: "#60db92",
          }}
          borderRadius={"8px 8px 0px 8px"}
          h={{ base: "28px", xl: "60px", "2xl": "80px" }}
          p={{ base: "10px", xl: "30px" }}
          fontSize={{ base: "12px", xl: "30px", "2xl": "40px" }}
          fontWeight={"normal"}
          color="gray.100"
          fontFamily={"Roboto"}
          mb={{ base: "48px", xl: 0 }}
        >
          Fale no WhatsApp
        </Button>
        <Image
          src="/images/logo-dralvaroamaral-2.png"
          zIndex={9999}
          mt={{ base: "0px", md: "20px" }}
          w={{ base: "50vw", xl: "500px" }}
        />
      </Flex>
    </Flex>
  );
}
