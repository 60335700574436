import { Button, Flex, Link, useMediaQuery } from "@chakra-ui/react";

export default function SectionA() {
  const [isLandscape] = useMediaQuery(
    "(max-width: 800px) and (orientation: landscape)"
  );
  return (
    <Flex
      bg="#262d2c"
      h={{
        base: "450px",
        md: isLandscape ? "100vh" : "760px",
        "2xl": "1000px",
      }}
      backgroundImage={{
        base: "url('/images/dralvaro.png')",
        md: "url('/images/dralvaro.png')",
      }}
      backgroundSize={{
        base: "270px",
        md: isLandscape ? "280px" : "480px",
        xl: "500px",
        "2xl": "700px",
      }}
      backgroundRepeat={"no-repeat"}
      backgroundPosition={{
        base: "left 5% bottom 0%",
        md: isLandscape ? "left 30% bottom 0%" : "left 10% bottom 0%",
        xl: "left 15% bottom 0%",
        "2xl": "left 20% bottom 0%",
      }}
      justifyContent={"center"}
    >
      <Flex w="100%" maxW="1600px" direction={"column"} alignItems={"center"}>
        <Flex
          w="100%"
          pt={{ base: "40px", md: isLandscape ? "20px" : "60px", xl: "80px" }}
          px={{ base: "20px", md: "120px", xl: "140px" }}
          justifyContent={{ base: "center", md: "flex-end" }}
        >
          <Button
            as={Link}
            isExternal
            href={
              "https://api.whatsapp.com/send?phone=5598992231294&text=Olá!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20o%20Dr.%20Alvaro%20Amaral"
            }
            fontFamily={"Roboto"}
            letterSpacing={"3px"}
            borderRadius={"12px"}
            colorScheme="green"
            bg="#50b579"
            // size={{ base: "md", xl: "lg" }}
            _hover={{ bg: "#60db92" }}
            h={{ base: "40px", xl: "60px", "2xl": "70px" }}
            w={{ base: "100%", xl: "650px" }}
            fontSize={{ base: "14px", xl: "2xl", "2xl": "3xl" }}
            fontWeight={"normal"}
          >
            AGENDE SUA CONSULTA AQUI
          </Button>
        </Flex>
        <Flex
          w="100%"
          maxW={{ base: "1000px", xl: "1000px", "2xl": "1200px" }}
          h="full"
          justifyContent={"flex-end"}
          alignItems={"center"}
          py={{
            base: "30px",
            md: isLandscape ? "100px" : undefined,
            xl: "160px",
            "2xl": "220px",
          }}
          pr={{ base: "20px", xl: undefined }}
        >
          <Flex
            w={{
              base: "40%",

              xl: "50%",
              "2xl": "500px",
            }}
            // h={{ base: "140px", md: "240px", xl: "300px" }}
            h="100%"
            backgroundImage={"url('/images/logo1.svg')"}
            backgroundSize={"contain"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={{ base: "center", "2xl": "right 5% bottom 0%" }}
          ></Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
