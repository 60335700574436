import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function SectionE() {
  return (
    <Center p="20px" flexDirection={"column"}>
      <Center
        alignSelf={"center"}
        p="12px"
        bg="#c9c5c2"
        borderRadius={"20px 20px 0px 20px"}
        fontFamily={"Sitka"}
        fontWeight={"medium"}
        fontSize={{ base: "18px", md: "2xl", xl: "30px", "2xl": "40px" }}
        mb={"20px"}
        // w={{ base: "80%", xl: "800px", "2xl": "1000px" }}
      >
        Dúvidas frequentes
      </Center>
      <Accordion className="accordion" allowMultiple w={"full"} maxW="900px">
        <AccordionItem className="hyphen">
          <h1>
            <AccordionButton _expanded={{ bg: "gray.400" }}>
              <AccordionIcon fontSize={"24px"} mr={"8px"} />
              <Box
                fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                O QUE FAZ O CIRURGIÃO VASCULAR?
              </Box>
            </AccordionButton>
          </h1>
          <AccordionPanel pb={4}>
            <Text>
              O cirurgião vascular é o especialista capacitado para diagnosticar
              e tratar as doenças relacionadas ao sistema circulatório, sejam
              elas de origem arterial, venosa ou linfática.
            </Text>
            <Text>
              Esse profissional é habilitado para realizar tratamentos das
              doenças vasculares de forma clínica, quando for o caso. Assim, o
              médico fará a indicação adequada de medicamentos, dispositivos não
              cirúrgicos e alterações nos hábitos de vida, controle de diabetes
              e colesterol, exercícios físicos, entre outros, auxiliando seus
              pacientes a melhorarem a qualidade de vida.
            </Text>
            <Text>
              Nos casos em que a melhor estratégia de tratamento for a
              realização de cirurgia, os cirurgiões vasculares são capacitados
              em todas as técnicas de tratamento.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className="hyphen">
          <h1>
            <AccordionButton _expanded={{ bg: "gray.400" }}>
              <AccordionIcon fontSize={"24px"} mr={"8px"} />
              <Box
                fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                O QUE FAZ UM CIRURGIÃO ENDOVASCULAR?
              </Box>
            </AccordionButton>
          </h1>
          <AccordionPanel pb={4}>
            <Text>
              A cirurgia endovascular é uma área da Cirurgia Vascular que
              proporciona o tratamento através de técnicas minimamente
              invasivas, feito a partir da inserção de um cateter nos vasos
              sanguíneos do paciente.
            </Text>
            <Text>
              Assim, não é necessário fazer cortes na pele como nas cirurgias
              convencionais. Este tipo de tratamento requer cortes menores e, em
              muitas vezes, pode ser realizado com um simples orifício na
              virilha. O tempo de recuperação é geralmente bem menor e mais
              confortável para o paciente
            </Text>
            <Text>
              Essa especialidade está em constante evolução, permitindo que cada
              vez mais doenças tenham seus tratamentos facilitados e
              simplificados.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className="hyphen">
          <h2>
            <AccordionButton _expanded={{ bg: "gray.400" }}>
              <AccordionIcon fontSize={"24px"} mr={"8px"} />
              <Box
                fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                PRINCIPAIS SINAIS E SINTOMAS DAS DOENÇAS VASCULARES
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Accordion allowMultiple>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      1. Dores na região das pernas
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Sentir dores ao se movimentar ou caminhar pode ser sinal de
                    problema na circulação sanguínea.
                  </Text>
                  <Text>
                    Esse problema deve ser avaliado para descartar causas como
                    varizes e comprometimento das artérias.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      2. Histórico familiar de doenças circulatórias
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Hereditariedade é um dos principais fatores de risco para
                    praticamente todas as doenças vasculares. Sendo assim,
                    história positiva para AVC, infartos, tromboses, varizes,
                    entre outras doenças circulatórias, em pais, mães, irmãos e
                    até avós, deve ser encarada como um alerta para uma
                    avaliação especializada precoce.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      3. Inchaço nos membros
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Apesar de ser comum episódios de edema (inchaço) esporádicos
                    no corpo, quando ele ocorre de forma constante pode ser
                    sinal de má circulação nos vasos linfáticos ou
                    comprometimento do retorno venoso.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      4. Câimbras
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    O excesso de cãibras pode indicar problemas circulatórios.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      5. Insensibilidade nas extremidades dos membros e/ou pés
                      gelados
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Alterações nas sensibilidades dos membros ou dos pés pode
                    indicar associação de doenças da circulação, principalmente
                    em pacientes com longa história de Diabetes.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      6. Lentidão na cicatrização de feridas
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Úlceras na pele com cicatrização difícil podem sugerir
                    vários tipos de problema, inclusive comprometimentos
                    circulatórios.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      7. Mudança da cor da pele
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Caso sua pele se torne mais avermelhada, acastanhada,
                    azulada ou até mesmo com coloração esbranquiçada pode
                    indicar problemas nos vasos e veias.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      8. Palidez na região dos pés
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Os pés pálidos podem indicar problemas de circulação
                    arterial, causado por aterosclerose e obstrução das
                    artérias.
                  </Text>
                  <Text>
                    Essa condição tem os mesmos fatores de risco para AVC e
                    infarto.
                  </Text>
                  <Text>
                    Então, fique ligado e procure um médico vascular
                    imediatamente se perceber palidez e dor nos pés.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      9. Pacientes em hemodiálise
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Os pacientes com doença renal crônica dialítica necessitam
                    de confecção de acessos para a realização de hemodiálise.
                    Esses acessos podem ser realizados por confecção de fístulas
                    arteriovenosas ou por meio de cateteres venosos.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      10. Sangramento transvaginal
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Pacientes com diagnósticos de miomatose uterina podem se
                    beneficiar do tratamento com embolização das artérias
                    uterinas. É um procedimento minimamente invasivo usado para
                    tratar miomas que causam sangramento menstrual intenso, dor
                    ou sintomas compressivos na bexiga ou intestino.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      11.Massa abdominal pulsátil
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    A massa abdominal pulsátil é um alerta clínico para a
                    presença de um possível aneurisma de aorta abdominal. Nesses
                    casos, o diagnóstico deve ser realizado o mais breve
                    possível.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className="hyphen">
          <h2>
            <AccordionButton _expanded={{ bg: "gray.400" }}>
              <AccordionIcon fontSize={"24px"} mr={"8px"} />
              <Box
                fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                QUANDO PROCURAR UM MÉDICO VASCULAR?
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Ao apresentar sintomas como dor e inchaço nos membros, alterações
              na temperatura corporal, formigamento e dificuldade para caminhar,
              o profissional deve ser consultado.
            </Text>
            <Text>
              O primeiro passo é avaliar a origem dos sintomas e, a partir daí,
              alcançar um diagnóstico assertivo.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className="hyphen">
          <h2>
            <AccordionButton _expanded={{ bg: "gray.400" }}>
              <AccordionIcon fontSize={"24px"} mr={"8px"} />
              <Box
                fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                SINAIS DE PROBLEMA DE CIRCULAÇÃO
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Existem certos sinais emitidos pelo corpo que indicam a possível
              existência de um problema vascular ou circulatório. Esses sintomas
              precisam ser investigados o quanto antes, já que o diagnóstico
              precoce de doenças vasculares é fundamental para a eficácia do
              tratamento.
            </Text>
            <Text>Alguns desses sinais e sintomas são:</Text>
            <VStack px="22px" fontSize={{ base: "lg", md: "xl", xl: "2xl" }}>
              <ul>
                <li>dormência ou formigamento nas extremidades;</li>
                <li>inchaço nas pernas;</li>
                <li>mudança de cor e temperatura em mãos e pernas;</li>
                <li>
                  alteração no funcionamento cerebral (perda de memória,
                  dificuldade de concentração);
                </li>
                <li>cãibras;</li>
                <li>cansaço e fadiga nas pernas, ao caminhar;</li>
                <li>sensação de peso e/ou queimação nas pernas;</li>
                <li>feridas que não cicatrizam nas pernas, pés e dedos;</li>
                <li>dor frequente nas pernas.</li>
              </ul>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className="hyphen">
          <h2>
            <AccordionButton _expanded={{ bg: "gray.400" }}>
              <AccordionIcon fontSize={"24px"} mr={"8px"} />
              <Box
                fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                as="span"
                flex="1"
                textAlign="left"
              >
                DOENÇAS TRATADAS PELO CIRURGIÃO VASCULAR
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Accordion allowMultiple>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Ateromatose
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Trata-se da formação de placas de gordura que se instalam
                    nas artérias. Essas placas podem se formar nas carótidas e
                    ocasionar o AVE, popularmente conhecido como “derrame”.
                    Quando estão nos membros inferiores, podem dificultar a
                    caminhada e predispor a formação de feridas.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Tromboses arteriais
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    A trombose arterial ocorre quando a formação de um coágulo
                    sanguíneo impede ou bloqueia completamente o fluxo de sangue
                    de uma artéria. Quando ocorre em vasos de médio e grande
                    porte pode causar condições graves de saúde e levar até a
                    perda do órgão.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Aneurismas
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Aneurisma é a dilatação anormal de um vaso, causada pelo
                    enfraquecimento de suas paredes ou por trauma, podendo
                    provocar a ruptura total do vaso sanguíneo. Os tipos mais
                    comuns de aneurisma são da artéria aorta abdominal ou da
                    aorta torácica, e seu rompimento aumenta consideravelmente o
                    risco de morte.
                  </Text>
                  <Text>
                    Além desses tipos de aneurismas centrais, existem os
                    aneurismas da artéria poplítea (região posterior do joelho)
                    que é responsável pela oclusão (entupimento) das artérias da
                    perna e consequentemente por amputações.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Varizes
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Doença que acomete as veias responsáveis pelo retorno do
                    sangue para o coração.
                  </Text>
                  <Text>
                    O envelhecimento e outros fatores de saúde deixam essas
                    veias dilatadas e com válvulas enfraquecidas, fazendo com
                    que o sangue se acumule em determinados pontos, formando as
                    varizes. Quadro mais avançados de doenças de varizes podem
                    levar à formação de manchas, irritação na pele e úlceras
                    crônicas nas pernas. Atualmente, o tratamento pode ser
                    realizado desde a cirurgia com a retirada das veias
                    varicosas até a realização de técnicas minimamente invasivas
                    com a radiofrequência.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Linfedemas
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Linfedema é o acúmulo de líquidos em determinada área do
                    corpo – geralmente, braços e pernas – o que leva ao inchaço.
                  </Text>
                  <Text>
                    É causado por uma obstrução do sistema linfático, ocasionado
                    pela remoção do linfonodo, ou por danos provocados por
                    tratamentos do câncer e outras condições.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Tromboses venosas
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    A trombose venosa ocorre quando um coágulo sanguíneo está
                    localizado em uma veia, sejam elas superficiais ou
                    profundas, de pequeno, médio ou grande calibre.
                  </Text>
                  <Text>
                    Quando uma trombose se forma em veias profundas, pode
                    ocasionar um tromboembolismo venoso, que é quando o trombo
                    se desprende do local de origem e circula pelo corpo,
                    podendo se alojar em órgãos como o pulmão, causando embolia
                    pulmonar; condição potencialmente fatal.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Linfangites e erisipelas
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    A linfangite é uma infecção do sistema linfático, enquanto a
                    erisipela é uma forma de linfangite determinada por um tipo
                    específico de estreptococo. Ocorrem com mais frequência em
                    pessoas com diabetes ou baixa capacidade imunológica.
                  </Text>
                  <Text>
                    Os principais sintomas incluem estrias avermelhadas, inchaço
                    dos linfonodos, febre, dor de cabeça e sensação de
                    mal-estar.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Acessos Vasculares
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Os pacientes renais crônicos realizam a hemodiálise por meio
                    de acesso vascular. Esse acesso pode ser por meio de
                    cateteres ou preferencialmente por meio de
                    fístulas-arteriovenosas. O cirurgião vascular é o médico
                    responsável pela confecção destes acessos.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Pé-diabético
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    É uma complicação do diabetes caracterizada por uma ferida
                    (úlcera) nos membros inferiores, agravada por uma infecção,
                    mas também pode englobar qualquer alteração de origem
                    neurológica, ortopédica ou vascular que afete essa região.
                  </Text>
                  <Text>
                    Além disso, as pessoas com diabetes tendem a apresentar
                    problemas de circulação, o que dificulta a chegada do sangue
                    até os membros mais distantes do coração, especialmente os
                    pés. Em consequência, essa região recebe menos oxigênio, o
                    que prejudica a cicatrização e pode levar à morte do tecido,
                    conhecida como necrose ou gangrena.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem className="hyphen">
                <h2>
                  <AccordionButton _expanded={{ bg: "gray.400" }}>
                    <AccordionIcon fontSize={"24px"} mr={"8px"} />
                    <Box
                      fontSize={{ base: "16px", md: "2xl", xl: "30px" }}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Miomatose Uterina
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Atualmente existe a possibilidade de realizar tratamento
                    para os casos de miomatose uterina por meio da embolização
                    das artérias que nutrem este órgão. Trata-se de um
                    procedimento menos invasivo e que pode ser indicado em
                    alguns casos.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Center>
  );
}
