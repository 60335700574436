export default function SectionG() {
  return (
    <iframe
      title="google-maps-iframe"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15944.153505253742!2d-44.2707693!3d-2.4942389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f68dc0b9947b75%3A0x2637638329b87e0d!2sInstituto%20de%20Cirurgia%20Vascular%20do%20Maranh%C3%A3o!5e0!3m2!1spt-BR!2sbr!4v1693506358448!5m2!1spt-BR!2sbr"
      width={"100%"}
      height="450"
      // style="border:0;"
      // allowfullscreen=""
      loading="lazy"
      // referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}
